<!-- 目的： 一个维度，根据圆点大小表示数量多少 -->
<template>
  <div ref="refContainer">
    <!-- <a-spin style="height: 100%;" :spinning="dataLoading"> -->
    <div id="container" :style="{ height: height + 'px' }"></div>
    <!-- </a-spin> -->
  </div>
</template>
<script>
import * as echarts from 'echarts'
import loadBMap from '@/utils/loadBMap'
import { getDiffValue } from '@/utils/utils'
import { debounce } from 'lodash'
require('echarts/extension/bmap/bmap')

export default {
  name: 'DataEntryFlowMap',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    theme: {
      type: String,
      default: 'light'
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      originData: [],
      geoField: '',
      geoFieldKeys: [],
      categorysName: '',
      seriesData: [],
      totalNum: 0,
      mapLevel: 7,
      mapCenterSite: {
        maxLng: null,
        minLng: null,
        maxLat: null,
        minLat: null
      },
      lightMapStyle: [{
        'featureType': 'water',
        'elementType': 'all',
        'stylers': {
          'color': '#d1d1d1'
        }
      }, {
        'featureType': 'land',
        'elementType': 'all',
        'stylers': {
          'color': '#f3f3f3'
        }
      }, {
        'featureType': 'railway',
        'elementType': 'all',
        'stylers': {
          'visibility': 'off'
        }
      }, {
        'featureType': 'highway',
        'elementType': 'all',
        'stylers': {
          'color': '#fdfdfd'
        }
      }, {
        'featureType': 'highway',
        'elementType': 'labels',
        'stylers': {
          'visibility': 'off'
        }
      }, {
        'featureType': 'arterial',
        'elementType': 'geometry',
        'stylers': {
          'color': '#fefefe'
        }
      }, {
        'featureType': 'arterial',
        'elementType': 'geometry.fill',
        'stylers': {
          'color': '#fefefe'
        }
      }, {
        'featureType': 'poi',
        'elementType': 'all',
        'stylers': {
          'visibility': 'off'
        }
      }, {
        'featureType': 'green',
        'elementType': 'all',
        'stylers': {
          'visibility': 'off'
        }
      }, {
        'featureType': 'subway',
        'elementType': 'all',
        'stylers': {
          'visibility': 'off'
        }
      }, {
        'featureType': 'manmade',
        'elementType': 'all',
        'stylers': {
          'color': '#d1d1d1'
        }
      }, {
        'featureType': 'local',
        'elementType': 'all',
        'stylers': {
          'color': '#d1d1d1'
        }
      }, {
        'featureType': 'arterial',
        'elementType': 'labels',
        'stylers': {
          'visibility': 'off'
        }
      }, {
        'featureType': 'boundary',
        'elementType': 'all',
        'stylers': {
          'color': '#fefefe'
        }
      }, {
        'featureType': 'building',
        'elementType': 'all',
        'stylers': {
          'color': '#d1d1d1'
        }
      }, {
        'featureType': 'label',
        'elementType': 'labels.text.fill',
        'stylers': {
          'color': '#999999'
        }
      }],
      darkMapStyle: [
        {
          'featureType': 'water',
          'elementType': 'all',
          'stylers': {
            'color': '#044161'
          }
        },
        {
          'featureType': 'land',
          'elementType': 'all',
          'stylers': {
            'color': '#004981'
          }
        },
        {
          'featureType': 'boundary',
          'elementType': 'geometry',
          'stylers': {
            'color': '#064f85'
          }
        },
        {
          'featureType': 'railway',
          'elementType': 'all',
          'stylers': {
            'visibility': 'off'
          }
        },
        {
          'featureType': 'highway',
          'elementType': 'geometry',
          'stylers': {
            'color': '#004981'
          }
        },
        {
          'featureType': 'highway',
          'elementType': 'geometry.fill',
          'stylers': {
            'color': '#005b96',
            'lightness': 1
          }
        },
        {
          'featureType': 'highway',
          'elementType': 'labels',
          'stylers': {
            'visibility': 'off'
          }
        },
        {
          'featureType': 'arterial',
          'elementType': 'geometry',
          'stylers': {
            'color': '#004981'
          }
        },
        {
          'featureType': 'arterial',
          'elementType': 'geometry.fill',
          'stylers': {
            'color': '#00508b'
          }
        },
        {
          'featureType': 'poi',
          'elementType': 'all',
          'stylers': {
            'visibility': 'off'
          }
        },
        {
          'featureType': 'green',
          'elementType': 'all',
          'stylers': {
            'color': '#056197',
            'visibility': 'off'
          }
        },
        {
          'featureType': 'subway',
          'elementType': 'all',
          'stylers': {
            'visibility': 'off'
          }
        },
        {
          'featureType': 'manmade',
          'elementType': 'all',
          'stylers': {
            'visibility': 'off'
          }
        },
        {
          'featureType': 'local',
          'elementType': 'all',
          'stylers': {
            'visibility': 'off'
          }
        },
        {
          'featureType': 'arterial',
          'elementType': 'labels',
          'stylers': {
            'visibility': 'off'
          }
        },
        {
          'featureType': 'boundary',
          'elementType': 'geometry.fill',
          'stylers': {
            'color': '#029fd4'
          }
        },
        {
          'featureType': 'building',
          'elementType': 'all',
          'stylers': {
            'color': '#1a5787'
          }
        },
        {
          'featureType': 'label',
          'elementType': 'labels.text.stroke',
          'stylers': {
            'color': '#004981'
          }
        },
        {
          'featureType': 'label',
          'elementType': 'labels.text.fill',
          'stylers': {
            'color': '#78aac3'
          }
        }
      ]
    }
  },
  computed: {},
  mounted() {
    const _this = this
    this.geoField = this.reportData['geoField']
    this.originData = this.reportData['dataItems']
    this.categorysName = this.reportData['metrics'][0]
    this.totalNum = 0
    this.geoFieldKeys = getDiffValue(this.originData, this.geoField)
    this.seriesData.splice(0)
    const getMapInfoPromiseArr = []
    this.geoFieldKeys.forEach(key => {
      getMapInfoPromiseArr.push(new Promise(function (resolve, reject) {
        _this.$api.speech.getMapInfo({ address: key }).then((res) => {
          const data = _this.originData.find(item => item[_this.geoField] === key)
          _this.seriesData.push(..._this.formateData(res, data))
        }).then(() => {
          resolve()
        })
      }))
    })
    Promise.all(getMapInfoPromiseArr).then(() => {
      this.getMapLevel()
      var dom = document.getElementById('container')
      var myChart = echarts.init(dom)
      var option
      let mapStyle = []
      if (this.theme === 'light') {
        mapStyle = this.lightMapStyle
      } else {
        mapStyle = this.darkMapStyle
      }
      option = {
        darkMode: true,
        title: {},
        tooltip: {
          trigger: 'item',
          confine: true,
          className: 'map-tooltip'
        },
        toolbox: {
          orient: 'vertical',
          left: 0,
          top: 0,
          feature: {
            myMagnify: {
              show: true,
              title: '放大',
              icon: 'path://M50 0 V 100 M0 50 H 100',
              onclick: function () {
                ++_this.mapLevel
                myChart.setOption({
                  bmap: {
                    zoom: _this.mapLevel
                  }
                })
                console.log('max', _this.mapLevel)
              }
            },
            myContraction: {
              show: true,
              title: '缩小',
              icon: 'path://M50 100 M0 50 H 100 M50 0',
              onclick: function () {
                --_this.mapLevel
                myChart.setOption({
                  bmap: {
                    zoom: _this.mapLevel
                  }
                })
              }
            },
            restore: { title: '重置' }
          }
        },
        bmap: {
          center: [(this.mapCenterSite['maxLng'] + this.mapCenterSite['minLng']) / 2, (this.mapCenterSite['maxLat'] + this.mapCenterSite['minLat']) / 2],
          zoom: this.mapLevel,
          roam: true,
          mapStyle: {
            styleJson: mapStyle
          }
        },
        series: [
          {
            type: 'effectScatter',
            coordinateSystem: 'bmap',
            data: this.seriesData,
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            symbolSize: function (val, params) {
              // console.log('params', params)
              let size = Math.abs(val[2]) / (_this.totalNum / _this.originData.length) * 15
              // console.log('avrange', _this.totalNum / _this.originData.length)
              // console.log('size', size)
              if (size > 50) size = 50
              else if (size > 0 && size < 10) size = 10
              return size
            },
            encode: {
              tooltip: [this.categorysName]
            },
            dimensions: ['lng', 'lat'].concat([this.categorysName]),
            itemStyle: {
              color: function (val) {
                return _this.theme === 'light' ? '#1067bf' : '#ef6300'
              }
            }
          }
        ]
      }

      if (option && typeof option === 'object') {
        loadBMap().then(() => {
          myChart.setOption(option)
        })
      }
    })

    this.chartRenderedDelay()
  },
  methods: {
    formateData (locationData, data) {
      return locationData.map(item => {
        const location = item['location'].split(',')
        const lng = Number(location[0])
        const lat = Number(location[1])
        this.totalNum += Math.abs(data[this.categorysName]) // 避免加上没有坐标位置的数据
        this.mapCenterSite['maxLng'] = this.mapCenterSite['maxLng'] !== null && this.mapCenterSite['maxLng'] > lng ? this.mapCenterSite['maxLng'] : lng
        this.mapCenterSite['minLng'] = this.mapCenterSite['minLng'] !== null && this.mapCenterSite['minLng'] < lng ? this.mapCenterSite['minLng'] : lng
        this.mapCenterSite['maxLat'] = this.mapCenterSite['maxLat'] !== null && this.mapCenterSite['maxLat'] > lat ? this.mapCenterSite['maxLat'] : lat
        this.mapCenterSite['minLat'] = this.mapCenterSite['minLat'] !== null && this.mapCenterSite['minLat'] < lat ? this.mapCenterSite['minLat'] : lat
        return {
          'name': item.formatted_address,
          'value': [lng, lat, data[this.categorysName]] // 第三项应为数据的值
        }
      })
    },
    getMapLevel () {
      if (this.mapCenterSite['maxLng'] - this.mapCenterSite['minLng'] > 5) this.mapLevel = 5
      else if (this.mapCenterSite['maxLat'] - this.mapCenterSite['minLat'] > 5) this.mapLevel = 5
      else this.mapLevel = 7
      return this.mapLevel
    }
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>
<style lang="less">
#container {
  .anchorBL {
    display: none;
  }
}
</style>
