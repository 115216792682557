<template>
  <van-action-sheet
    class="benchmarking-popup"
    v-model="visible"
    close-on-click-action
    get-container="body"
    title="行业对标">
    <van-form @submit="onOk" label-width="4.5em">
      <van-field name="formCategory" label="行业分类">
        <template #input>
          <van-radio-group v-model="form.category" direction="horizontal">
            <van-radio v-for="(item, index) in categories" :name="item" :key="index">{{ item }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="formMetrics" label="指标名称">
        <template #input>
          <van-checkbox-group v-model="form.metrics" direction="horizontal">
            <van-checkbox v-for="(item, index) in metrics" :name="item" shape="square" :key="index">{{ item }}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field name="formType" label="对标类型">
        <template #input>
          <van-checkbox-group v-model="form.type" direction="horizontal">
            <van-checkbox name="max" shape="square">最大值</van-checkbox>
            <van-checkbox name="min" shape="square">最小值</van-checkbox>
            <van-checkbox name="avg" shape="square">平均数</van-checkbox>
            <van-checkbox name="mid" shape="square">中位数</van-checkbox>
            <van-checkbox name="pos" shape="square">N位数</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field v-if="showTypeNPos" name="formShowTypeNPos" label="N位数">
        <template #input>
          <van-stepper v-model="form.typeNPos" min="10" max="90" step="10" />
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button
          round
          block
          :loading="confirmLoading"
          :disabled="confirmLoading"
          type="primary"
          native-type="submit">确定</van-button>
      </div>
    </van-form>
  </van-action-sheet>
</template>

<script>
//
import ReportChartData from '@/utils/reportData'
import { mapState } from 'vuex'
import { Toast } from 'vant'

const companyName = '公司名称'
const benchmarkingDataTypes = [
  { key: 'max', label: '最大值' },
  { key: 'min', label: '最小值' },
  { key: 'avg', label: '平均数' },
  { key: 'mid', label: '中位数' },
  { key: 'pos', label: 'N位数' } // 1-100从大到小，10 step
]

const dataFields = [{
  fieldName: '年份',
  fieldId: ''
}, {
  fieldName: '年季度',
  fieldId: ''
}, {
  fieldName: '季度',
  fieldId: ''
}, {
  fieldName: '年月',
  fieldId: ''
}, {
  fieldName: '月份',
  fieldId: ''
}, {
  fieldName: '日期',
  fieldId: ''
}]
export default {
  name: 'BenchmarkingPopup',
  components: {
  },
  props: {
    keywordMatch: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      benchmarkingDataTypes,
      form: {
        category: '',
        metrics: [],
        type: [],
        typeNPos: 10
      },
      visible: false,
      confirmLoading: false,
      categories: [],
      metrics: [],
      metricsReportData: [],
      reportData: {}
    }
  },
  computed: {
    ...mapState({
      benchmarkingCategory: state => state.speech.benchmarkingCategory,
      benchmarkingDataset: state => state.speech.benchmarkingDataset
    }),
    showTypeNPos () {
      return this.form.type.indexOf('pos') > -1
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    show () {
      this.visible = true
    },
    init () {
      this.benchmarkingCategory.forEach(category => {
        const _category = `${category['门类']}/${category['大类']}/${category['小类']}/${category['小类1']}`
        if (this.categories.indexOf(_category) === -1) {
          this.categories.push(_category)
        }
        if (this.metrics.indexOf(category['关键指标']) === -1) {
          this.metrics.push(category['关键指标'])
        }
      })
      if (this.benchmarkingDataset.dataFields) {
        const _dateField = this.benchmarkingDataset.dataFields.find(item => item.extendDate === true)
        if (_dateField) {
          dataFields.forEach(item => {
            const _filed = _dateField.extendFields.find(field => field.aliasName === item.fieldName)
            if (_filed) {
              item.fieldId = _filed.id
            } else {
              item.fieldId = _dateField.id
            }
          })
        }
      }
    },
    onCancel () {
      this.visible = false
    },
    onOk () {
      if (this.form.category.length === 0) {
        Toast('请选择行业分类')
      } else if (this.form.metrics.length === 0) {
        Toast('请选择指标名称')
      } else if (this.form.type.length === 0) {
        Toast('请选择对标类型')
      } else {
        this.combineKeyword()
      }
    },
    combineKeyword () {
      this.metricsReportData.splice(0)
      let _keywordPrefix = companyName
      let _dimensions = ''
      this.keywordMatch.forEach(item => {
        if (item.keywordCategory === 'DATETIME') {
          _keywordPrefix += item.matchedValue
        }
        if (item.keywordCategory === 'DIMENSION_NAME') {
          _keywordPrefix += item.matchedValue
          _dimensions = item.keywordOutputDirect
        }
      })
      _keywordPrefix += this.form.category.split('/')[3]
      this.confirmLoading = true
      this.form.metrics.forEach(item => {
        this.metricsReportData.push({
          dimensions: _dimensions,
          metrics: item,
          keyword: _keywordPrefix + item,
          keywordMatch: [],
          reportDataRequest: {},
          reportData: [],
          maxDataItems: [],
          minDataItems: [],
          midDataItems: [],
          avgDataItems: [],
          posDataItems: [],
          loaded: false
        })
      })
      this.getKeywordMatch()
    },
    getKeywordMatch() {
      const _metricsReportData = this.metricsReportData.find(item => item.loaded === false)
      if (!_metricsReportData) {
        this.confirmLoading = false
        this.transformData()
      } else {
        this.$api.benchmarking.getKeywordMatch(_metricsReportData.keyword).then(res => {
          _metricsReportData.keywordMatch = res.keywordMatch
          _metricsReportData.reportDataRequest = res.reportDataRequest
          this.getReportData(_metricsReportData)
        }).catch(error => {
          this.confirmLoading = false
          Toast(error.response.data.message)
        })
      }
    },
    getReportData (metricsReportData) {
      this.$api.benchmarking.getReportData(metricsReportData.reportDataRequest).then(res => {
        metricsReportData.reportData = new ReportChartData(res[0])
        metricsReportData.loaded = true
        this.getKeywordMatch()
      }).catch(error => {
        this.confirmLoading = false
        Toast(error.response.data.message)
      })
    },
    transformData () {
      console.log(this.metricsReportData)
      this.metricsReportData.forEach(reportDataItem => {
        const _dataItems = reportDataItem.reportData.dataItems
        const _unit = reportDataItem.reportData.units[0].unit
        const _companyAmountCounts = []
        const _companyDataItems = {}
        const _datetimeDataItems = {}
        _dataItems.forEach(dataItem => {
          if (!_companyDataItems[dataItem[companyName]]) {
            _companyDataItems[dataItem[companyName]] = []
          }
          const _tmp = JSON.parse(JSON.stringify(dataItem))
          delete _tmp[companyName]
          _companyDataItems[dataItem[companyName]].push(_tmp)

          let _companyAmountCount = _companyAmountCounts.find(_cacItem => _cacItem[companyName] === dataItem[companyName])
          if (!_companyAmountCount) {
            _companyAmountCount = {}
            _companyAmountCount[companyName] = dataItem[companyName]
            _companyAmountCount.total = 0
            _companyAmountCounts.push(_companyAmountCount)
          }
          _companyAmountCount.total += _tmp[reportDataItem.metrics]

          let _tmp1 = _datetimeDataItems[dataItem[reportDataItem.dimensions]]
          if (!_tmp1) {
            _datetimeDataItems[dataItem[reportDataItem.dimensions]] = _tmp1 = {
              amount: 0,
              quantity: 1
            }
          }
          _tmp1.amount += dataItem[reportDataItem.metrics]
          _tmp1.quantity += 1
        })
        _companyAmountCounts.sort((a, b) => {
          return a.total > b.total ? -1 : 1
        })
        // console.log(JSON.stringify(_companyAmountCounts))
        const _maxCompany = _companyAmountCounts[0][companyName]
        const _minCompany = _companyAmountCounts[_companyAmountCounts.length - 1][companyName]
        const _midCompany = _companyAmountCounts[Math.ceil(_companyAmountCounts.length / 2) - 1][companyName]
        const _posN = Math.ceil(_companyAmountCounts.length * this.form.typeNPos / 100)
        const _posCompany = _companyAmountCounts[_posN - 1][companyName]

        const _avgDataItems = []
        Object.keys(_datetimeDataItems).forEach(key => {
          const _tmp2 = {}
          _tmp2[reportDataItem.dimensions] = key
          _tmp2[reportDataItem.metrics + '-平均数'] = _datetimeDataItems[key].amount / _datetimeDataItems[key].quantity
          _avgDataItems.push(_tmp2)
        })
        reportDataItem.avgDataItems = {
          unit: _unit,
          metrics: reportDataItem.metrics + '-平均数',
          data: _avgDataItems
        }
        reportDataItem.maxDataItems = {
          unit: _unit,
          metrics: reportDataItem.metrics + '-最大值',
          data: this.fixDataKey(_companyDataItems[_maxCompany], reportDataItem.dimensions, reportDataItem.metrics, '最大值')
        }
        reportDataItem.minDataItems = {
          unit: _unit,
          metrics: reportDataItem.metrics + '-最小值',
          data: this.fixDataKey(_companyDataItems[_minCompany], reportDataItem.dimensions, reportDataItem.metrics, '最小值')
        }
        reportDataItem.midDataItems = {
          unit: _unit,
          metrics: reportDataItem.metrics + '-中位数',
          data: this.fixDataKey(_companyDataItems[_midCompany], reportDataItem.dimensions, reportDataItem.metrics, '中位数')
        }
        reportDataItem.posDataItems = {
          unit: _unit,
          metrics: reportDataItem.metrics + '-' + this.form.typeNPos + '位数',
          data: this.fixDataKey(_companyDataItems[_posCompany], reportDataItem.dimensions, reportDataItem.metrics, this.form.typeNPos + '位数')
        }
      })
      this.appendDataItems()
    },
    appendDataItems () {
      const _resultData = []
      this.metricsReportData.forEach(reportDataItem => {
        if (this.form.type.indexOf('max') > -1) _resultData.push(reportDataItem.maxDataItems)
        if (this.form.type.indexOf('min') > -1) _resultData.push(reportDataItem.minDataItems)
        if (this.form.type.indexOf('mid') > -1) _resultData.push(reportDataItem.midDataItems)
        if (this.form.type.indexOf('avg') > -1) _resultData.push(reportDataItem.avgDataItems)
        if (this.form.type.indexOf('pos') > -1) _resultData.push(reportDataItem.posDataItems)
      })
      this.$emit('appendBenchmarkingData', _resultData)
      this.visible = false
    },
    fixDataKey (data, dimensions, metrics, suffix) {
      return data.map(item => {
        const _result = {}
        _result[dimensions] = item[dimensions]
        _result[metrics + '-' + suffix] = item[metrics]
        return _result
      })
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        window.history.pushState({ title: '图表对标', url: '#' }, '图表对标', '#')
      } else if (history.state.title === '图表对标') {
        history.back()
        history.back() // 回退两次包括操作选项的历史
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .benchmarking-popup {
    .van-checkbox {
      margin-bottom: 3px;
    }
  }
</style>
