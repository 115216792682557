<template>
  <van-action-sheet
    class="predict-popup"
    v-model="visible"
    close-on-click-action
    get-container="body"
    title="预测">
    <van-form @submit="onSubmit">
      <van-field name="form_count" label="展示粒度">
        <template #input>{{ xAxis }}</template>
      </van-field>
      <van-field name="form_count" label="预测点数">
        <template #input>
          <van-stepper v-model="form.count" />
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        name="picker"
        :value="form.fieldTypeName"
        label="样本粒度"
        right-icon="arrow-down"
        @click="showFieldTypePicker = true"
      />
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">确定</van-button>
      </div>
    </van-form>
    <van-popup v-model="showFieldTypePicker" position="bottom" get-container="body" safe-area-inset-bottom :close-on-popstate="true">
      <van-picker
        show-toolbar
        :columns="predictDimensionsColumns"
        @confirm="onFieldTypeConfirm"
        @cancel="showFieldTypePicker = false"
      />
    </van-popup>
  </van-action-sheet>
</template>

<script>
import { predictDimensions } from '@/utils/dimensionUtil'

export default {
  name: 'PredictPopup',
  components: {
  },
  props: {
    xAxis: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      form: {
        count: 3,
        fieldTypeName: '',
        fieldType: ''
      },
      visible: false,
      showFieldTypePicker: false,
      disabled: false,
      predictDimensions: []
    }
  },
  computed: {
    predictDimensionsColumns () {
      return this.predictDimensions.map(item => item['simple'])
    }
  },
  mounted() {
    const _index = predictDimensions.findIndex(item => item['alias'].indexOf(this.xAxis) > -1)
    if (_index === -1) {
      this.disabled = true
    } else {
      this.predictDimensions = predictDimensions.slice(_index)
      if (this.predictDimensions.length > 0) {
        this.form.fieldType = this.predictDimensions[0]['key']
        this.form.fieldTypeName = this.predictDimensions[0]['simple']
      } else {
        this.disabled = true
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    onSubmit () {
      this.visible = false
      this.$eventBus.$emit('predictData', this.form)
    },
    onFieldTypeConfirm (value, index) {
      this.form.fieldTypeName = value
      this.form.fieldType = this.predictDimensions[index].key
      this.showFieldTypePicker = false
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        window.history.pushState({ title: '图表预测', url: '#' }, '图表预测', '#')
      } else if (history.state.title === '图表预测') {
        history.back()
        history.back() // 回退两次包括操作选项的历史
      }
    },
    showFieldTypePicker (newVal) {
      if (newVal) {
        window.history.pushState({ title: '预测样本粒度', url: '#' }, '预测样本粒度', '#')
      } else if (history.state.title === '预测样本粒度') {
        history.back()
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .predict-popup {
  }
</style>
