export const datetimeFieldTypes = [
  { key: 'YEAR', label: '年份' },
  { key: 'YEAR_QUARTER', label: '年季度' },
  { key: 'QUARTER', label: '季度' },
  { key: 'YEAR_MONTH', label: '年月' },
  { key: 'MONTH', label: '月份' },
  { key: 'YEAR_WEEK', label: '年星期' },
  { key: 'WEEK', label: '星期' },
  { key: 'DATE', label: '日期' }
]

export const predictDimensions = [
  { key: 'YEAR', label: '年份', simple: '年', alias: ['年份', '年'] },
  { key: 'YEAR_QUARTER', label: '年季度', simple: '季度', alias: ['年季度', '季度'] },
  { key: 'YEAR_MONTH', label: '年月', simple: '月', alias: ['年月', '月份', '月'] },
  { key: 'YEAR_WEEK', label: '年星期', simple: '周', alias: ['年星期', '周', '星期'] },
  { key: 'DATE', label: '日期', simple: '天', alias: ['日期', '天', '日'] }
]

export function isDatetimeDimension (str) {
  return !!datetimeFieldTypes.find(item => item['label'] === str)
}
